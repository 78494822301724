import { Component, OnInit } from '@angular/core';
import { CommonService } from "../services/common.service"

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  allBlogs: any
  public page = 1;
  count = 0
  constructor(
    private service: CommonService
  ) { }

  ngOnInit(): void {
    this.getBlogs()
    this.blogs()
  }
  getBlogs(){
    this.service.getAllBlogs().subscribe(res => {
     this.count = res.result.length
    })
  }
  blogs() {
    this.service.getBlogs(this.page, 9).subscribe(res => {
      this.allBlogs = res.result;
      window.scroll(0,0);
    })
  }
  public pageChange(event): void {
		this.page = event;
		this.blogs();
	}
}
