<!-- <app-blog-header></app-blog-header> -->
<app-header></app-header>


<section class="blog-details-box wow fadeInUp">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-sm-12">
        <img class="bg-img" src="{{blogDetail?.image}}">
        <div class="row">
          <div class="col-md-9 m-auto">
            <div class="card blog">
                <div class="blog-heading">
                   <h6>Blog</h6>
                   <h1 class="title"><a href="#">{{blogDetail?.title}}</a>
                </h1>
                <p>AI helps sales reps generate high quality leads, automate tasks and produce meaningful insights about their leads and customers</p>
                </div>
                <div class="blog-details">
                  <p [innerHTML]=detail></p>
                </div>
              </div>

              <div class="more-links-box">
                <h2 class="more">More like this</h2>
                <div class="more-article row">
                  <div class="display" *ngFor="let x of allBlogs">
                    <div class="wow fadeInRight" data-wow-delay="0.5s"
                      (click)="goToBlogs(x.id)">
                      <img class="blog-img" src="{{x.image}}">
                      <p class="article-title">{{x.title}}<br>
                      </p>
                      <p class="date">{{x.date | date}}</p>
                    </div>
                  </div>

                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
