import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller, private router: Router) { }

  ngOnInit(): void {
  }
  goToProducts() {
    window.scrollTo(0, 0);
  }
  onClickScroll(elementId: string): void {
    this.router.navigate(['']);
    
    setTimeout(() => {
      console.log(elementId);
      // navbarTogglerDemo03
      document.querySelector("#navbarTogglerDemo03").classList.remove("show");
      this.viewportScroller.scrollToAnchor(elementId)
    }, 5);

  }

  

}
