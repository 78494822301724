<!-- <app-blog-header></app-blog-header> -->
<!-- <app-header></app-header> -->

<section class="bolg-lst-sec">
  <div class="container-fluid p-0">
      <div class="row">
          <div class="col-md-12">
            <div class="top">
              <img class="bg-img wow fadeInUp" data-wow-duration="2s" src="assets/blog/ai.jpg">
              <div class="card blog">
                <div class="blog-heading">
                   <h6>Blog</h6>
                   <h1 class="title"><a href="#">Tips to Improve Sales Performance with AI</a>
                </h1>
                  <p>AI helps sales reps generate high quality leads, automate tasks and produce meaningful insights about their leads and customers</p>
                </div>
               <div class="row">
                 <div class="col-md-12">
                   <div class="display mar">
                     <div class="wow fadeInRight blog-inner-box" data-wow-delay="0.5s"
                            *ngFor="let x of allBlogs
                            | paginate: {
                              itemsPerPage: 10,
                              currentPage: page,
                              totalItems: count
                            }" routerLink='/blog/{{x.id}}'>
                            <img class="blog-img" src="{{x.image}}">
                            <p class="article-title">{{x.title}}<br>
                            </p>
                            <p class="date">{{x.date | date}}</p>
                          </div>
                 </div>
                 <pagination-controls class="pagination" (pageChange)="pageChange($event)"
                  maxSize="9" responsive="true"
                  previousLabel="Previous" nextLabel="Next"></pagination-controls>
                 </div>
               </div>
                
              </div>

            </div>
          </div>
      </div>
  </div>
</section>
