import { Component, OnInit } from '@angular/core';
import {CommonService} from "../services/common.service"

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.css']
})
export class TermsAndConditionComponent implements OnInit {
  content:any
  constructor(private service:CommonService) { }

  ngOnInit(): void {
    this.service.getTermsCondition().subscribe((data)=>{
      this.content = data.result.privacy_description_en
    })
  }

}
