import { Component, OnInit } from '@angular/core';
import {CommonService} from "../services/common.service"

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  content:any
  constructor(private service:CommonService) { }

  ngOnInit(): void {
    this.service.getPrivacyPolicy().subscribe((data)=>{
      this.content = data.result.privacy_description_en
    })
  }

}
