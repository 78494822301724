import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  //public URL: any = "https://questdeveloper.com/whizleads_backend/api/";
  public URL: any = "https://whizleads.com/admin/api/";

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }


  constructor(
    private http: HttpClient
  ) { }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  public getBlogs(page: number, limit: number): Observable<any> {
    return this.http.get(this.URL + 'blog-list?offset=' + page + '&limit=' + limit).pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }
  public getAllBlogs(): Observable<any> {
    return this.http.get(this.URL + 'blog-list?').pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }
  public getBlogDetail(id): Observable<any> {
    return this.http.get(this.URL + 'blog-detail?blog_id=' + id ).pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }
  public getHomeContent(): Observable<any> {
    return this.http.get(this.URL + 'blog-list?api_name=front_content').pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }
 
  public sendEmail(email): Observable<any> {
    return this.http.get(this.URL + 'blog-list?email=' + email).pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }

   public getTermsCondition(): Observable<any> {
    return this.http.get(this.URL + 'privacy-policy?privacy_id=2').pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }

  public getPrivacyPolicy(): Observable<any> {
    return this.http.get(this.URL + 'privacy-policy?privacy_id=1').pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }

}
