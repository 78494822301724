import { Component, OnInit } from '@angular/core';
import { CommonService } from "../services/common.service"

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  downloaded: boolean
  email: any
  sales: any
  ios: any
  constructor(private service: CommonService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.getHome()
  }
  download() {

    this.service.sendEmail(this.email).subscribe(res => {
      this.email = "";
      this.downloaded = true;

      setTimeout(() => {
        this.downloaded = false;
      }, 8000);


    })

  }
  getHome() {
    this.service.getHomeContent().subscribe(res => {
      this.sales = res.result.salesforce_app
      this.ios = res.result.iOS_app
    })
  }
}
