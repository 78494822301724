<section class="footer-sec">
    <div class="container">
         <div class="row bg-white  ">
                <div class="col-md-1" id="first">
                    <img class="img-fluid" style="width: 100%;" src="assets/logo/Logo.png">
                </div>
                <div class="col-md-7 col-12" id="second">

                    <p>© 2021 Whizleads. All Rights Reserved.<span>
                    Please read our <a routerLink='/terms-conditions'>Terms and Conditions</a></span>
                    <span>
                    and <a routerLink='/privacy-policy'>Privacy Policy</a></span></p>
                </div>
                <div class="col-md-4 col-12" id="third">
                    <div class="find-links">
                        <h3>Find us on</h3>
                        <div>
                            <a href="https://twitter.com/whizleads"><img class="marg" src="assets/footer/tweet.png"></a>
                            <a href="https://www.facebook.com/whizleads/"><img class="marg" src="assets/footer/fb.png"></a>
                            <a href="https://medium.com/@whizleads"><img style="height: 25px;" class="marg" src="assets/footer/medium.png"></a>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</section>
