import { Component, OnInit } from '@angular/core';
import { CommonService } from "../services/common.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.css']
})
export class ViewBlogComponent implements OnInit {
  blogDetail: any
  blogId:any
  detail:any
  allBlogs:any
  constructor( private service: CommonService,
    private router: ActivatedRoute,
    private route: Router) {
    this.blogId = this.router.snapshot.params["id"];
   }

  ngOnInit(): void {
    this.blogs()
    this.getBlogDetails()
  }
  getBlogDetails() {
    this.service.getBlogDetail(this.blogId).subscribe(res => {
      this.blogDetail = res.result
      this.detail = res.result.details
    })
  }
  blogs() {
    this.service.getBlogs(1, 6).subscribe(res => {
      this.allBlogs = res.result
    })
  }
  goToBlogs(blogId){
    this.route.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
  }
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigateByUrl('/blog/'+ blogId)

  }
}
