import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { BlogComponent } from './blog/blog.component';
import { ViewBlogComponent } from './view-blog/view-blog.component';
import { BlogHeaderComponent } from './blog-header/blog-header.component';
import { NgwWowModule } from 'ngx-wow';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {CommonService} from "./services/common.service";
import {NgxPaginationModule} from 'ngx-pagination';
import { Location, LocationStrategy, HashLocationStrategy,PathLocationStrategy } from '@angular/common';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    BlogComponent,
    ViewBlogComponent,
    BlogHeaderComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgwWowModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule
  ],
  providers: [CommonService,
    [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]],
  bootstrap: [AppComponent]
})
export class AppModule {
  location: Location;
	constructor(location: Location) {
		this.location = location;

	}
}
