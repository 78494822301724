<!-- <app-header></app-header> -->


<section class="banner-sec" style="background-image: url(assets/vector/vector-bg1.png), url(assets/vector/vector-bg2.png);">
  <div class="bg">
    <div class=" just one wow fadeInDown" id="home">
        <img src="assets/vector/Vector2.png">
        <img class="marg wow fadeInLeft" src="assets/vector/Vector3.png" data-wow-duration="2s">
        <img class="marg wow fadeInLeft" src="assets/vector/Vector5.png" data-wow-duration="2s">
        <img class="marg wow fadeInLeft" src="assets/vector/Vector4.png" data-wow-duration="2s">
        <img class="marg wow fadeInLeft" src="assets/vector/Vector7.png" data-wow-duration="2s">
        <img class="marg marg1 wow fadeInLeft" src="assets/vector/Vector8.png" data-wow-duration="2s">
      </div>
  </div>
  <div class="container-fluid p-0">
      <div class="row wow fadeInDown align-item-center" data-wow-duration="1s">
         <div class="col-lg-7 col-md-7 order-2 col-sm-7">
             <div class="desktop-img">
              <img class="lap" src="assets/home/home-laptop-image-new.png" class="img-fluid">
            </div>
          </div>
          <div class="col-lg-4 offset-lg-1 col-md-5 col-sm-5">
            <div class="for-mobil-banner">
              <h1 class="banner-heading"><span class="white-banner-head">Close leads through</span><br>machine learning</h1>
            <p class="para">A simple sales app to maximize sales<br> using Artificial Intelligence</p>
            <div class="display">
                <a href="https://apps.apple.com/us/app/whizleads/id957721089"><img src="assets/home/app-store-new.png" style="    height: 50px;"></a>
              <a href=""><img src="assets/home/salesforce.png"></a>
             
            </div>
            </div>
          </div>
          
      </div>
      <div class="for-mobile-logo" style="display: none;">
        <div class="bg">
            <div class=" just one" id="home">
                <img src="assets/vector/Vector2.png">
                <img class="marg " src="assets/vector/Vector3.png">
                <img class="marg " src="assets/vector/Vector5.png">
                <img class="marg " src="assets/vector/Vector4.png">
                <img class="marg marg1" src="assets/vector/Vector8.png">
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-lg-3 offset-lg-1 col-md-5">
          <h4>
            <h3 class="real-time-heading"><span class="color-white">Real time Insights</span>  <br>about prospects</h3>
          </h4>
        </div>
        <div class="col-lg-5 col-md-7">
           <p class="real-time-pera">Gather real-time Insights about prospects and gaining a deeper understanding of
            prospective
            customer’s
            interests, personalities and moods using existing CRM data to develop meaningful relationships and optimize
            your sales pitch and communication with prospective customers.</p>
        </div>
      </div>
  </div>
</section>



<section class="second-sales-sec" style="background-image: url(assets/vector/vector-bg3.png), url(assets/vector/vector-bg4.png), url(assets/vector/vector-bg5.png);position: relative;">
   <div class="container-fluid p-0" id="product">
    <div class="row align-item-center">
      <div class="col-md-4 offset-md-1">
        <div class="side-text">
          <p class="small-heading">Products</p>
            <h2 class="banner-heading"><span class="color-gray">Sales Intelligence</span></h2>
            <h2 class="banner-heading">just got a whole</h2>
            <h2 class="banner-heading">lot smarter</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="for-mobile-logo " style="display: none;">
        <div class="bg">
            <div class=" just one" id="home">
                <img src="assets/vector/Vector2.png">
                <img class="marg " src="assets/vector/Vector3.png">
                <img class="marg " src="assets/vector/Vector5.png">
                <img class="marg " src="assets/vector/Vector4.png">
                <img class="marg marg1" src="assets/vector/Vector8.png">
              </div>
          </div>
      </div>
  <div class="desktop-img">
    <img class="lap" src="assets/home/second-screen.png" class="img-fluid">
  </div>
</section>

<section class="second-sales-sec for-sales-app" >
  <div class="container-fluid p-0">

    <div class="row">
      <div class="col-md-5 offset-md-1">
        <div>
           <h4 class="midium-heading">Salesforce App</h4>
            <div class="content-extra">

              <p class="par" [innerHTML]=sales></p>
            </div>

            <div>
               <a href=""><img src="assets/home/salesforce.png"></a>
              
            </div>
        </div>
      </div>
       <div class="for-mobile-logo " style="display: none;">
        <div class="bg">
            <div class=" just one" id="home">
                <img src="assets/vector/Vector2.png">
                <img class="marg " src="assets/vector/Vector3.png">
                <img class="marg " src="assets/vector/Vector5.png">
                <img class="marg " src="assets/vector/Vector4.png">
                <img class="marg marg1" src="assets/vector/Vector8.png">
              </div>
          </div>
      </div>
      <div class="col-md-5">
        <div>
           <h4 class="midium-heading">iOS app</h4>
            <div class="content-extra">
                <p class="par" [innerHTML]=ios></p>
            </div>

            <div>
             
              <a href="https://apps.apple.com/us/app/whizleads/id957721089"><img src="assets/home/app-store-new.png"></a>
            </div>
        </div>

      </div>
    </div>
  </div>
</section>

<section class="banner-sec flexible-sec" style="background-image: url(assets/vector/vector-bg6.png);">
  
  <div class="container-fluid p-0" id="pricing">
      <div class="row wow fadeInDown align-item-center" data-wow-duration="1s">
      
          <div class="col-md-4 offset-md-1">
            <div class="">
              <p class="small-heading color-green">Pricing</p>
              <h1 class="banner-heading"><span class="white-banner-head">Flexible</span><br>pricing plans</h1>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
             <div class="bg">
                <div class=" just one wow fadeInDown" id="home">
                    <img src="assets/vector/Vector2.png">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector3.png" data-wow-duration="2s">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector5.png" data-wow-duration="2s">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector4.png" data-wow-duration="2s">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector7.png" data-wow-duration="2s">
                    <img class="marg marg1 wow fadeInLeft" src="assets/vector/Vector8.png" data-wow-duration="2s">
                  </div>
              </div>
          </div>
      </div>
  
  <div class="row pt-tiral-200">
    <div class="col-md-10 offset-md-1">
      <div class="row">
         <div class="col-md-4">
            <div class="trial-pack-box">
               <div class="trial-content">
                  <p class=" color-green">Trial Subscription</p>
                  
                  <ul>
                    <li><span></span> Free trial for 30 days</li>
                    <li><span></span> Maximum of 5 leads for analysis</li>
                    <li><span></span> Insights and Tips features enabled</li>
                    
                  </ul>  
                 </div>
                <div>
                  <a href="">Get Started</a>
                </div>
            </div>
          </div>
           <div class="col-md-4">
            <div class="trial-pack-box">
                <div class="trial-content">
                  <p class=" color-green">Premium Subscription</p>
                
                  <ul>
                    <li><span></span> Maximum of 30 leads for insights</li>
                    <li><span></span> $12.95 per month (per user)</li>
                    <li><span></span> Insights and Tips features enabled</li>
                    
                  </ul>
                </div>
                <div>
                  <a href="">Get Started</a>
                </div>
            </div>
          </div>
           <div class="col-md-4">
            <div class="trial-pack-box">
                <div class="trial-content">
                  <p class=" color-green">Enterprise Subscription</p>
                
                    <ul>
                      <li><span></span> Discounts for grouped subscription</li>
                      <li><span></span> Insights and Tips features enabled</li>
                      <li><span></span> Customized features available on request</li>
                      
                    </ul>
                </div>
                <div>
                  <a href="">Get Started</a>
                </div>
            </div>
          </div>
          
      </div>
    </div>
   </div>
   <div>
    <h6 class="text-center center-heading-look">Looking for an enterprise plan? Reach out to us at <span class="color-green">launch@whizleads.com</span> to discuss a tailored enterprise plan.</h6>
   </div>
   </div>
</section>


<section class="banner-sec flexible-sec e-book-sec" style="background-image: url(assets/vector/vector-bg7.png), url(assets/vector/vector-bg8.png);">
  
  <div class="container-fluid p-0" id="e-book">
      <div class="row wow fadeInDown align-item-center" data-wow-duration="1s">
      
          <div class="col-md-4 offset-md-1">
            <div class="">
              <p class="small-heading color-green">E-BOOK</p>
              <h1 class="banner-heading"><span class="white-banner-head">Get our free</span><br>e-book</h1>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
             <div class="bg">
                <div class=" just one wow fadeInDown" id="home">
                    <img src="assets/vector/Vector2.png">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector3.png" data-wow-duration="2s">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector5.png" data-wow-duration="2s">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector4.png" data-wow-duration="2s">
                    <img class="marg wow fadeInLeft" src="assets/vector/Vector7.png" data-wow-duration="2s">
                    <img class="marg marg1 wow fadeInLeft" src="assets/vector/Vector8.png" data-wow-duration="2s">
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-6 order-2">
          <div class="img-book-ebook">
            <img src="assets/home/book.png" class="img-fluid">
          </div>
        </div>
        <div class="col-md-5 offset-md-1">
          <div  class="email-box-book">
            <h5>Enter your email and the book is all yours</h5>
            <div class="input-group mb-3">
                 <input type="email" class="form-control" placeholder="Your Email Address" [(ngModel)]="email">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="download()">Download</button>
                </div>
              </div>
            <p class="message-alert" *ngIf="downloaded">Thank you for your interest.</p>
          </div>
        </div>
      
      </div>
   </div>
</section>