<div>
  <div class="row pos">
    <img src="assets/t&c.jpg">
    <h1 class="align">Privacy Policy</h1>
  </div>
  <div class="row" style="margin-top: 5rem;">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">
      <p [innerHTML]=content></p>
    </div>
    <div class="col-sm-2"></div>

  </div>
</div>
