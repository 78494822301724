<nav class="navbar navbar-expand-lg bg-head">

    <button class="navbar-toggler hvr-bounce-to-bottom collapsed" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03"
        aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <a href="" class="navbar-brand"><img src="assets/logo/Logo.png"></a>
        <div class=" nav-item for-mobile-show" style="display: none;">
        <a class="nav-link list for-blogs" routerLink='/blog' style="padding-right: 34px;">Blogs <span class="arrow"></span></a>
            </div>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav mt-2 mt-lg-0" style="margin-right:3rem">
            <li class="nav-item">
                <a class="nav-link list" (click)="onClickScroll('home')">Home
                </a>
            </li>
            <li class=" nav-item">
                <a class="nav-link list" (click)="onClickScroll('product')">
                    Products
                </a>
            </li>
            <li class=" nav-item">
                <a class="nav-link list" (click)="onClickScroll('pricing')">Pricing</a>
            </li>
            <li class=" nav-item">
                <a class="nav-link list" (click)="onClickScroll('e-book')">E-book
                </a>
            </li>
            <li class=" nav-item">
                <a class="nav-link list for-blogs" routerLink='/blog'>Blogs <span class="arrow"></span></a>
            </li>
        </ul>
         
    </div>

</nav>
 
